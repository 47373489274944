<template>
  <div class="page">
    <!-- 酒店订单详情 -->
    <div class="top_info_box">
      <div class="top">
        <p>所属酒店</p>
        <p>订单状态：<span>{{
            info.status == 1
            ? '待支付'
            : info.status == 2
            ? '已支付'
            : info.status == 3
            ? '已超时'
            : info.status == 4
            ? '已取消'
            : info.status == 5
            ? '退款成功'
            : ''
            }}</span></p>
      </div>
      <div class="content">
        <div class="left">
          <img :src="$global.regexImg(info.room.cover)"
               alt="">
          <div class="img"></div>
        </div>
        <div class="right">
          <p>{{ info.room.name}}</p>
          <p>{{ info.date}} - {{ info.end_date}}</p>
          <p v-if='info.hotel'>{{info.hotel.address }}</p>
          <p v-if="!info.hotel"
             class="address"></p>
        </div>
      </div>
    </div>
    <!--  -->

    <div class="room_box">
      <p> <span>{{ info.room.area }}m2</span>|
        <span>{{ info.room.prople_num }}人</span>|
        <span>{{ info.room.window == 2 ? '无窗' : '有窗' }}</span>|
        <span>{{ info.room.date }}</span>|
        <span>{{ info.room.wifi == 2 ? '无wifi' : '有wifi' }}</span>|
        <span>{{ info.room.smoke == 2 ? '不可抽烟' : '可抽烟' }}</span>|
        <span>{{ info.room.breakfast == 2 ? '无早餐' : '有早餐' }}</span></p>
      <p><span>{{ info.date}} - {{ info.end_date}}</span><span>共{{info.date_num}}晚</span><span>共间</span></p>
    </div>
    <!-- 入住信息 -->
    <div class="info_box">
      <div class="top">
        入住信息
      </div>
      <div class="content_row">
        <p><span>入住人：</span><span>{{ info.pay_name}}</span></p>
        <p><span>联系电话：</span><span>{{ info.pay_phone}}</span></p>
        <p><span>预计到店：</span><span>{{info.date }}<i v-if='info.room.remark'>{{info.room.remark}}</i></span></p>
        <p><span>订单金额：</span><span>{{ info.amount }}</span></p>
        <p><span>所属会议：</span><span>{{ info.meet }}</span></p>
      </div>
    </div>
    <!-- 订单信息 -->
    <div class="info_box">
      <div class="top">
        订单信息
      </div>
      <div class="content_row">
        <p><span>订单号：</span><span>{{ info.order_no}}</span></p>
        <p><span>创建时间：</span><span>{{ info.created_at }}</span></p>
      </div>
    </div>
    <!-- money -->
    <div class="money_box">
      <p><span>应付金额：</span><span>¥{{info.amount}}</span></p>
      <div class="button_box">
        <el-button class="btn1"
                   v-if="info.status == 1"
                   @click.stop="onPay(info.id)">去支付</el-button>
        <el-button @click="onMerchant(info.hotel.phone ? info.hotel.phone : '')">联系商家</el-button>
        <el-button v-if="info.status == 1"
                   :loading='loading  && operationStatus == 1'
                   @click.stop="operationStatus = 1;onCancel(info.id)">取消订单</el-button>
      </div>
    </div>
    <textarea style="opacity:0;width:1px;height:1px"
              id="input"
              key="textarea">复制</textarea>
  </div>
</template>

<script>
import { get_hotelOrder, set_cancelRoom, set_refund } from '@/utils/Api/meeting'
export default {
  components: {},
  props: [],
  data () {
    return {
      operationStatus: null,
      loading: false,
      orderId: null,
      info: {
        room: {},
        hotel: {}
      }
    }
  },
  methods: {
    async getInfo () {
      var res = await get_hotelOrder({ page: 1, limit: 1, type: 2, room_order_id: this.orderId })
      if (res) {
        this.info = res.data[0]
      }
    },
    onPay (id) {
      this.$router.push({ path: '/onlinePayment', query: { type: 2, orderId: id } })
    },
    onCancel (id, i) {
      if (this.loading) return
      this.$confirm('您确认取消订单吗？', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning'
      }).then(async () => {
        this.loading = true
        var res = await set_cancelRoom({ order_id: id })
        if (res) {
          this.$message.success('订单已取消')
          this.getInfo()
        } else {
          this.$message.warning('操作失败')
        }
      }).catch(() => { })
    },
    onMerchant (x) {
      if (!x) return this.$message.success('当前酒店已关闭线上预定')
      var input = document.getElementById('input')
      input.value = x
      input.select()
      document.execCommand('copy')
      this.$message.success('商家联系方式已复制！')
    },
  },
  created () {
    this.orderId = this.$route.query.orderId
    this.getInfo()
  },
  mounted () { },
  destroyed () { },
  computed: {},
  watch: {}
}
</script>

<style lang="scss" scoped>
.page {
  padding-left: 22px;
  box-sizing: border-box;
}
.top_info_box {
  margin-bottom: 17px;
  width: 100%;
  font-size: 13px;
  color: #333;
  .content {
    border-left: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    padding: 30px 18px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .left {
      text-align: center;
      height: 108px;
      width: 187px;
      position: relative;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 108px;
        max-width: 187px;
      }
      .img {
        height: 108px;
        width: 187px;
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
    .right {
      text-align: left;
      width: calc(100% - 205px);
      > p:nth-child(1) {
        width: 60%;
        height: 50px;
        margin-bottom: 4px;
        color: #333;
        font-size: 16px;
        font-weight: 700;
        display: block;
        overflow: hidden;
        text-overflow: ellispsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      > p:nth-child(2) {
        color: #8e8e8e;
        font-size: 12px;
        margin-bottom: 16px;
      }
      > p:nth-child(3) {
        color: #333;
        font-size: 13px;
      }
      .address {
        height: 13px;
        width: 80px;
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
  .top {
    text-align: left;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    height: 41px;
    line-height: 41px;
    background: #f7fbff;
    display: flex;
    justify-content: space-between;
  }
}
.info_box {
  margin-bottom: 17px;
  .top {
    text-align: left;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    height: 41px;
    line-height: 41px;
    background: #f7fbff;
  }
  .content_row {
    border-left: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    padding: 30px 18px 15px;
    box-sizing: border-box;
    text-align: left;
    i {
      margin-left: 20px;
      font-style: normal;
    }
    p {
      width: 100%;
      box-sizing: border-box;
      text-align: left;
      margin-bottom: 12px;
      font-size: 13px;
      display: flex;
      justify-content: space-between;
      span:nth-child(1) {
        width: 120px;
        font-size: 13px;
        line-height: 18px;
        color: #333;
      }
      span:nth-child(2) {
        width: calc(100% - 120px);
        line-height: 18px;
        color: #666;
      }
    }
  }
}
.money_box {
  margin: 70px 0 100px;
  padding-right: 30px;
  box-sizing: border-box;
  text-align: right;
  > p {
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: #333;
    margin-bottom: 20px;
    span:nth-child(1) {
      margin-right: 20px;
    }
    span:nth-child(2) {
      color: #f03708;
    }
  }
  > div {
    display: flex;
    flex-direction: row-reverse;
    .el-button {
      align-self: center;
      width: 73px;
      height: 28px;
      font-size: 13px;
      line-height: 28px;
      margin-bottom: 5px;
      border: 1px solid #47d7e3;
      color: #47d7e3;
      padding: 0;
      margin-left: 8px;
      border-radius: 2px;
    }
    .btn1 {
      background: #47d7e3;
      border-radius: 2px;
      color: #fff;
    }
  }
}
.room_box {
  width: 100%;
  margin-bottom: 17px;
  box-sizing: border-box;
  padding: 15px 20px;
  border: 1px solid #f3f3f3;
  > p:nth-child(1) {
    text-align: left;
    font-size: 13px;
    line-height: 18px;
    color: #333;
    font-weight: 700;
    margin-bottom: 9px;
    span {
      padding: 0 5px;
    }
  }
  > p:nth-child(2) {
    text-align: left;
    font-size: 12px;
    line-height: 17px;
    color: #8e8e8e;
    span {
      margin-right: 10px;
    }
  }
}
</style>
